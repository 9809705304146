<div class="modal-header">
  <h5 class="modal-title f-w-600" id="exampleModalLabel">{{title}}
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="this.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="needs-validation">
    <div class="form">
      <div class="form-group">
        <label for="validationCustom01" class="mb-1">{{modalData.type=='category'?'Category Name':'Subcategory
          Name'}}</label>
        <input class="form-control" id="validationCustom01" type="text" [(ngModel)]="category.name"
          [ngModelOptions]="{standalone: true}">
      </div>
      <div class="form-group" *ngIf="modalData.type=='subcategory'">
        <label for="validationCustom01" class="mb-1">Category</label>
        <select class="form-control" [(ngModel)]="category.category_id" [ngModelOptions]="{standalone: true}">
          <option *ngFor="let category of modalData.data.categories" [ngValue]="category.id">{{category.name}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="validationCustom02" class="mb-1">{{modalData.type=='category'?'Category':'Subcategory'}} Image
          URL</label>
        <input class="form-control" id="validationCustom02" type="text" [(ngModel)]="category.image_src"
          [ngModelOptions]="{standalone: true}">
      </div>
      <div class="form-group" *ngIf="this.modalData.type=='subcategory'">
        <label for="validationCustom02" class="mb-1">Keywords</label>
        <textarea class="form-control" id="validationCustom02" type="text" [(ngModel)]="category.keywords"
          [ngModelOptions]="{standalone: true}"></textarea>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="this.send()">Save</button>
  <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="this.close()">Close</button>
</div>
