<div class="modal-header">
  <h5 class="modal-title f-w-600" id="exampleModalLabel">Decline With a Message</h5>
  <button type="button" class="close" aria-label="Close" (click)="this.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="needs-validation">
    <div class="form">
      <div class="form-group">
        <label for="validationCustom01" class="mb-1">Reason For Declining</label>
        <textarea [(ngModel)]="declineMessage" [ngModelOptions]="{standalone: true}" class="form-control"></textarea>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="this.send()">Decline</button>
  <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="this.close()">Cancel</button>
</div>
