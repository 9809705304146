import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/service/auth.service';
import { LocalStoreService } from 'src/app/shared/service/local-store.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  loading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private jwtHelper: JwtHelperService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private store: LocalStoreService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.createLoginForm();
    // this.createRegisterForm();
  }

  owlcarousel = [
    {
      title: 'Welcome to CallaStyle',
      desc: 'Admin Portal',
    },
    // {
    //   title: 'Welcome to CallaStyle',
    //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    // },
    // {
    //   title: 'Welcome to CallaStyle',
    //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    // },
  ];
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true,
  };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      userName: [''],
      password: [''],
      confirmPassword: [''],
    });
  }

  ngOnInit() {
    if (this.authService.checkAuth()) {
      this.router.navigateByUrl('/dashboard/default');
      return;
    }
  }

  async onSubmit() {
    if (!this.loginForm.valid) {
      this.toastr.error('Please correct the values.', 'Invalid Input', {
        timeOut: 5000,
      });
      return;
    }
    this.recaptchaV3Service
      .execute('login_admin')
      .subscribe(async (tk: string) => {
        this.loading = true;
        const data = {
          ...this.loginForm.value,
          recaptcha_token: tk,
        };
        var res = await this.authService.signin(data);
        if (res.status === 0) {
          this.toastr.error(
            'Something is wrong! Please try again later.',
            'Opps!',
            { timeOut: 4000 }
          );
          this.loading = false;
          return;
        }
        if (res.status !== 200 && res.status) {
          this.toastr.error(res.error.message, 'Oops!', { timeOut: 3000 });
          this.loading = false;
          return;
        }
        this.loading = false;
        var tokens: any = res.data;
        var tokenPayload = this.jwtHelper.decodeToken(tokens.token);

        if (tokenPayload.account_type == 'client') {
          this.toastr.error("Client can't access admin panel", 'Oops!', {
            timeOut: 3000,
          });
          this.loading = false;
          return;
        }

        this.store.setItem('tk', tokens.token);
        this.store.setItem('ftk', tokens.ftoken);
        this.store.setItem('first_name', tokenPayload.first_name);
        this.store.setItem('email', tokenPayload.email);
        this.store.setItem('last_name', tokenPayload.last_name);
        this.store.setItem('phone_number', tokenPayload.phone_number);
        this.store.setItem('date_created', tokenPayload.date_created);
        this.store.setItem('account_type', tokenPayload.account_type);
        this.store.setItem('address', tokenPayload.address);
        this.store.setItem('city', tokenPayload.city);

        this.toastr.success(
          'Successfully logged in',
          `Welcome ${tokenPayload.first_name}`,
          { timeOut: 2000 }
        );

        this.router.navigateByUrl('/dashboard/default');
        this.loading = false;
      });
  }
}
