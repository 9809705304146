import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-form-category',
  templateUrl: './form-category.component.html',
  styleUrls: ['./form-category.component.scss'],
})
export class FormCategoryComponent implements OnInit {
  @Input() modalData: any;

  category = { name: '', image_src: '', category_id: -1, keywords: '' };
  title: string = '';
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.modalData.type == 'category') {
      if (this.modalData.action == 'add') {
        this.title = 'Add Category';
        return;
      }
      this.title = 'Update Category';
    } else {
      if (this.modalData.action == 'add') {
        this.title = 'Add Subcategory';
        return;
      }
      this.title = 'Update Subcategory';
    }
    if (this.modalData.action == 'update') {
      if (this.modalData.type == 'subcategory') {
        this.modalData.data.data = {
          ...this.modalData.data.data,
          category_id: this.modalData.data.data.category.id,
        };
      }
      this.category = this.modalData.data.data;
    }
  }

  send() {
    if (this.modalData.type == 'category') {
      this.activeModal.close({
        action: this.modalData.action,
        category: this.category,
      });
      return;
    }
    if (this.category.category_id == -1) {
      return;
    }
    this.activeModal.close({
      action: this.modalData.action,
      subcategory: {
        ...this.category,
        category: { id: this.category.category_id },
      },
    });
  }

  close() {
    this.activeModal.dismiss('Closed');
  }
}
