import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { CallaBackendConfig } from './config';
import { LocalStoreService } from './local-store.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authenticated: boolean = false;
  public isUserLogged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private store: LocalStoreService,
    private router: Router,
    private http: HttpClient
  ) {}

  getAuthHeader(): HttpHeaders {
    const tk = this.store.getItem('tk');
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + tk,
    });
  }

  checkAuth(): boolean {
    const tk = this.store.getItem('tk');
    if (tk && tk !== '') {
      this.isUserLogged.next(true);
      return (this.authenticated = true);
    }
    this.isUserLogged.next(false);
    return (this.authenticated = false);
  }

  signup(data) {
    return this.http
      .post(CallaBackendConfig.REGISTER, JSON.stringify(data), {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  signin(credentials) {
    return this.http
      .post(CallaBackendConfig.LOGIN, JSON.stringify(credentials), {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  updateUserProfile(data) {
    return this.http
      .post(CallaBackendConfig.UPDATE_USER, JSON.stringify(data), {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  activateUser(data) {
    return this.http
      .post(CallaBackendConfig.ACTIVATE_USER, JSON.stringify(data), {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  getAdminFirebaseToken() {
    return this.http
      .get(CallaBackendConfig.GET_AMDIN_FIREBASE_TOKEN, {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  getuser() {
    return of({});
  }

  handleError(err) {
    return err;
  }

  getToken(): string {
    return this.store.getItem('tk');
  }

  signout() {
    this.authenticated = false;
    this.store.clear();
    this.router.navigateByUrl('/auth/login');
  }

  getUser(email: string = '') {
    return this.http
      .get(CallaBackendConfig.GET_USER + '/' + email, {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  updateUserCommission(data) {
    return this.http
      .put(CallaBackendConfig.UPDATE_USER_COMMISSION, JSON.stringify(data), {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }
}
