import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './physical/category/category.component';
import { SubCategoryComponent } from './physical/sub-category/sub-category.component';
import { ProductListComponent } from './physical/product-list/product-list.component';
import { AddProductComponent } from './physical/add-product/add-product.component';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { ProductDetailComponent } from './physical/product-detail/product-detail.component';
import { UpdateProductComponent } from './physical/update-product/update-product.component';
import { UnverifiedStockListComponent } from './physical/unverified-stock-list/unverified-stock-list.component';
import { DeclinedStockListComponent } from './physical/declined-stock-list/declined-stock-list.component';
import { ExpiredStockListComponent } from './physical/expired-stock-list/expired-stock-list.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'physical/category',
        component: CategoryComponent,
        data: {
          title: 'Category',
          breadcrumb: 'List',
        },
      },
      {
        path: 'physical/sub-category',
        component: SubCategoryComponent,
        data: {
          title: 'Sub Category',
          breadcrumb: 'List',
        },
      },
      {
        path: 'physical/product-list',
        component: ProductListComponent,
        data: {
          title: 'Product List',
          breadcrumb: 'List',
        },
      },
      {
        path: 'physical/uproduct-list',
        component: UnverifiedStockListComponent,
        data: {
          title: 'Unverified Stock List',
          breadcrumb: 'List',
        },
      },
      {
        path: 'physical/expired-list',
        component: ExpiredStockListComponent,
        data: {
          title: 'Expired Stock List',
          breadcrumb: 'List',
        },
      },
      {
        path: 'physical/declined-list',
        component: DeclinedStockListComponent,
        data: {
          title: 'Declined Stock List',
          breadcrumb: 'List',
        },
      },
      {
        path: 'physical/product-detail',
        component: ProductDetailComponent,
        data: {
          title: 'Product Detail',
          breadcrumb: 'Detail',
        },
      },
      {
        path: 'physical/add-product',
        component: AddProductComponent,
        data: {
          title: 'Add Products',
          breadcrumb: 'Add Product',
        },
      },
      {
        path: 'physical/update-product',
        component: UpdateProductComponent,
        data: {
          title: 'Stock Item',
          breadcrumb: 'Stock Item',
        },
      },
      {
        path: 'digital/digital-category',
        component: DigitalCategoryComponent,
        data: {
          title: 'Category',
          breadcrumb: 'Category',
        },
      },
      {
        path: 'digital/digital-sub-category',
        component: DigitalSubCategoryComponent,
        data: {
          title: 'Sub Category',
          breadcrumb: 'Sub Category',
        },
      },
      {
        path: 'digital/digital-product-list',
        component: DigitalListComponent,
        data: {
          title: 'Product List',
          breadcrumb: 'Product List',
        },
      },
      {
        path: 'digital/digital-add-product',
        component: DigitalAddComponent,
        data: {
          title: 'Add Products',
          breadcrumb: 'Add Product',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule {}
