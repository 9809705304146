<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Categories</h5>
        </div>
        <div class="card-body">
          <div class="btn-popup pull-right">
            <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
              data-target="#exampleModal" (click)="open([], 'add')">Add Category</button>
          </div>
          <div id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive">
              <ng2-smart-table [settings]="settings" [source]="categories" (edit)="this.open($event, 'update')"
                (delete)="this.deleteCategory($event)">
              </ng2-smart-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
