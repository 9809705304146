import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategoryService } from 'src/app/shared/service/category.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { AddImageComponent } from '../add-image/add-image.component';
import swal from 'sweetalert2';
import { DeclineModalComponent } from '../decline-modal/decline-modal.component';

@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrls: ['./update-product.component.scss'],
})
export class UpdateProductComponent implements OnInit {
  public productData;

  public productForm: UntypedFormGroup;
  public counter: number = 1;
  public variants = [];
  public properties = { new: true, sale: false, category: -1, subcategory: -1 };
  public categories = [];
  public subcategories = [];
  public images = [];
  public mode = 'verified';

  public image = {
    src: 'assets/images/pro3/1.jpg',
    image_id: '',
  };
  public loading = false;
  public verified = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private categoryService: CategoryService,
    private modalService: NgbModal,
    private productService: ProductService
  ) {
    this.productData = this.router.getCurrentNavigation().extras.state;
    this.setPreData();
  }

  ngOnInit(): void {
    this.getCategories();
    this.getSubcategories(this.properties.category);
  }

  setPreData() {
    this.variants = this.productData.product.variants;
    this.images = this.productData.product.images;
    this.properties.category = this.productData.product.category;
    this.properties.subcategory = this.productData.product.subcategory;
    this.properties.sale = this.productData.product.sale;
    this.properties.new = this.productData.product.new;
    this.counter = this.productData.product.stock;
    this.verified = this.productData.mode == 'verified';
    this.image = this.images[0];
    this.mode = this.productData.mode;

    this.productForm = this.fb.group({
      title: [this.productData.product.title, [Validators.required]],
      description: [
        this.productData.product.description,
        [Validators.required],
      ],
      price: [this.productData.product.price, [Validators.required]],
      discount: [this.productData.product.discount, [Validators.required]],
    });
  }

  toggleNew() {
    this.properties.new = !this.properties.new;
  }

  toggleSale() {
    this.properties.sale = !this.properties.sale;
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  async getCategories() {
    const response = await this.categoryService.getCategories();
    if (!response.data) {
      return;
    }
    this.categories = response.data;
  }

  async getSubcategories(id) {
    const response = await this.categoryService.getSubCategoriesWithID(id);
    if (!response.data) {
      return;
    }
    const subcategories: Array<any> = response.data;
    this.subcategories = subcategories.sort(this.compare);
  }

  private compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  addProductVariant() {
    this.images = [
      ...this.images,
      {
        src: 'assets/images/user.png',
        image_id: '',
      },
    ];

    this.variants = [
      ...this.variants,
      {
        variant_id: '',
        size: [],
        color: '',
        image_id: '',
      },
    ];
  }

  open(indexImage) {
    const checkImage = this.images[indexImage];
    if (checkImage.image_id != '') {
      this.image = checkImage;
      return;
    }
    const modalRef = this.modalService.open(AddImageComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });

    modalRef.result
      .then((objectData) => {
        this.images[indexImage] = objectData.image;
        this.variants[indexImage] = objectData.variant;
      })
      .catch((err) => {});
  }

  changeCategory(id) {
    this.getSubcategories(id);
  }

  // async updateProduct() {
  //   const filteredImages = this.images.filter((image) => {
  //     if (image.image_id && image.image_id != '') return image;
  //   });
  //   const filteredVariants = this.variants.filter((v) => {
  //     if (v.variant_id && v.variant_id != '') return v;
  //   });
  //   if (filteredImages.length == 0 || filteredVariants.length == 0) {
  //     return;
  //   }
  //   if (this.properties.category == -1 || this.properties.subcategory == -1) {
  //     return;
  //   }
  //   const product = {
  //     ...this.productForm.value,
  //     variants: filteredVariants,
  //     images: filteredImages,
  //     stock: this.counter,
  //     ...this.properties,
  //   };
  //   this.loading = true;
  //   const response = await this.productService.updateProduct(
  //     this.productData.productKey,
  //     product
  //   );
  //   swal.fire({
  //     title: 'Success',
  //     text: 'Product updated!',
  //     icon: 'success',
  //   });
  //   this.loading = false;
  // }

  async verification() {
    const product = this.productData.product;
    const key = this.productData.productKey;
    this.loading = true;
    if (this.verified) {
      swal
        .fire({
          title: 'Confirm',
          text: "You're about to revoke a stock from the public page!",
          icon: 'question',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          showCancelButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.productService.removeUProduct({
              key: key,
              product: product,
            });
            this.loading = false;
            swal.fire({
              title: 'Success',
              text: 'Stock revoked',
              icon: 'success',
            });
            this.router.navigate(['/products/physical/product-list']);
            // this.getCategories();
          } else {
            this.loading = false;
          }
        });
      return;
    }
    swal
      .fire({
        title: 'Confirm',
        text: "You're about to verify a stock!",
        icon: 'question',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await this.productService.addUProduct(product);
          this.loading = false;
          swal.fire({
            title: 'Success',
            text: 'Stock verified',
            icon: 'success',
          });
          // this.getCategories();
          this.router.navigate(['/products/physical/uproduct-list']);
        } else {
          this.loading = false;
        }
      });
  }

  declineProduct() {
    const modalRef = this.modalService.open(DeclineModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });

    modalRef.result
      .then(async (objectData) => {
        this.loading = true;
        const product = this.productData.product;
        const data = {
          ...objectData,
          product: product,
        };
        await this.productService.declineProduct(data);
        this.loading = false;
        swal.fire({
          title: 'Success',
          text: 'Product declined',
          icon: 'success',
        });
        this.router.navigate(['/products/physical/uproduct-list']);
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  removeImage(i) {
    if (this.images.length == 1) return;
    this.images.splice(i, 1);
    this.variants.splice(i, 1);
  }

  editImage(indexImage) {
    const checkImage = this.images[indexImage];
    if (checkImage.image_id == '') {
      return;
    }
    const modalRef = this.modalService.open(AddImageComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });

    modalRef.componentInstance.editImage = {
      image: checkImage,
      variant: this.variants[indexImage],
    };

    modalRef.result
      .then((objectData) => {
        this.images[indexImage] = objectData.image;
        this.variants[indexImage] = objectData.variant;
      })
      .catch((err) => {
        this.loading = false;
      });
  }
}
