import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategoryService } from 'src/app/shared/service/category.service';
import { FormCategoryComponent } from '../form-category/form-category.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss'],
})
export class SubCategoryComponent implements OnInit {
  public closeResult: string;
  public sub_categories = [];
  public categories = [];
  public category = -1;

  constructor(
    private modalService: NgbModal,
    private categoryService: CategoryService
  ) {}

  open(data, action) {
    const modalData = {
      data: { ...data, categories: this.categories },
      action: action,
      type: 'subcategory',
    };
    const modalRef = this.modalService.open(FormCategoryComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });

    modalRef.componentInstance.modalData = modalData;

    modalRef.result
      .then(async (result) => {
        if (
          result.subcategory.name == '' ||
          result.subcategory.img_src == '' ||
          result.subcategory.category.id == -1
        ) {
          swal.fire({
            title: 'Empty Data',
            icon: 'error',
            text: 'Fill all fields',
          });
          return;
        }
        if (result.action == 'add') {
          await this.addSubCategory(result.subcategory);

          this.getSubCategories();
          return;
        }
        await this.updateSubCategory(result.subcategory);

        this.getSubCategories();
      })
      .catch((err) => {});
  }

  public settings = {
    actions: {
      position: 'right',
      add: false,
    },
    mode: 'external',
    columns: {
      // numID: {
      //   title: 'ID',
      //   editable: false,
      //   filter: false,
      // },
      // image_seen: {
      //   title: 'Image',
      //   filter: false,
      //   type: 'html',
      // },
      name: {
        title: 'Name',
        filter: false,
      },
      category_name: {
        title: 'Category',
        filter: false,
      },
    },
  };

  ngOnInit() {
    this.getCategories();
    this.getSubCategories();
  }

  changeCategory(id) {
    if (id == -1) {
      this.getSubCategories();
      return;
    }

    this.getSubcategories(id);
  }

  async getSubcategories(id) {
    const response = await this.categoryService.getSubCategoriesWithID(id);
    if (!response.data) {
      return;
    }
    let num = 0;

    const subcategories: Array<any> = response.data.map((category) => {
      num = num + 1;
      return {
        ...category,
        numID: num,
        category_name: category.category.name,
        image_seen: `<img src='${category.image_src}' class='imgTable'>`,
      };
    });
    this.sub_categories = subcategories.sort(this.compare);
  }

  private compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  async getSubCategories() {
    const response = await this.categoryService.getSubCategories();
    if (!response.data) {
      return;
    }
    let num = 0;
    const subcategories: Array<any> = response.data.map((category) => {
      num = num + 1;
      return {
        ...category,
        numID: num,
        category_name: category.category.name,
        image_seen: `<img src='${category.image_src}' class='imgTable'>`,
      };
    });
    this.sub_categories = subcategories.sort(this.compare);
  }

  async getCategories() {
    const response = await this.categoryService.getCategories();
    if (!response.data) {
      return;
    }
    this.categories = response.data;
  }

  async addSubCategory(subcategory) {
    const response = await this.categoryService.createSubCategory(subcategory);
    if (response.code == 201) {
      swal.fire({
        title: 'Success',
        text: 'Subcategory created!',
        icon: 'success',
      });
      return;
    }
    swal.fire({
      title: 'Oops!',
      text: response.message,
      icon: 'error',
    });
  }

  async updateSubCategory(subcategory) {
    const response = await this.categoryService.updateSubCategory(subcategory);
    if (response.code == 200) {
      swal.fire({
        title: 'Success',
        text: 'Subcategory updated!',
        icon: 'success',
      });
      return;
    }
    swal.fire({
      title: 'Oops!',
      text: response.message,
      icon: 'error',
    });
  }

  async deleteSubCategory(subcategory) {
    const response = await this.categoryService.deleteSubategory(subcategory);
  }

  async deleteSCategory(event) {
    swal
      .fire({
        title: 'Confirm',
        text: 'Are you sure you want to delete?',
        icon: 'question',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteSubCategory(event.data);
          swal.fire({
            title: 'Success',
            text: 'Subcategory deleted!',
            icon: 'success',
          });
          this.getSubCategories();
        }
      });
  }
}
