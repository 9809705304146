import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss'],
})
export class AddImageComponent implements OnInit {
  @Input() public editImage;

  constructor(public activeModal: NgbActiveModal) {}

  public image_id = uuidv4();
  public image = { src: '', image_id: this.image_id };
  public variant = {
    variant_id: uuidv4(),
    size: [],
    color: '#ffffff',
    image_id: this.image_id,
  };
  public sizes = ['S', 'M', 'L', 'XL', 'XXL'];

  ngOnInit(): void {
    if (this.editImage) {
      this.image.src = this.editImage.image.src;
      this.image.image_id = this.editImage.image.image_id;
      this.variant.size = this.editImage.variant.size;
      this.variant.color = this.editImage.variant.color;
      this.variant.image_id = this.editImage.variant.image_id;
    }
  }

  close() {
    this.activeModal.dismiss('Closed');
  }

  send() {
    this.activeModal.close({
      variant: this.variant,
      image: this.image,
    });
  }
}
