<div class="modal-header">
  <h5 class="modal-title f-w-600" id="exampleModalLabel">Add Product Variant</h5>
  <button type="button" class="close" aria-label="Close" (click)="this.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="needs-validation">
    <div class="form">
      <div class="form-group">
        <label for="validationCustom01" class="mb-1">Image URL</label>
        <input class="form-control" id="validationCustom01" type="text" [(ngModel)]="image.src"
          [ngModelOptions]="{standalone: true}">
      </div>
      <div class="form-group">
        <!-- <label for="validationCustom01" class="mb-1">Size</label>
        <select class="form-control" [(ngModel)]="variant.size" [ngModelOptions]="{standalone: true}">
          <option *ngFor="let size of sizes" [ngValue]="size">{{size | uppercase}}</option>
        </select> -->

        <ng-select [items]="sizes" bindLabel="name" placeholder="Select item" appendTo="body" [multiple]="true"
          [(ngModel)]="variant.size" [ngModelOptions]="{standalone: true}">
        </ng-select>
      </div>
      <div class="form-group">
        <input [(colorPicker)]="variant.color" [style.background]="variant.color" />
      </div>
      <!-- <div class="form-group">
        <label for="validationCustom02" class="mb-1">LabelTwo</label>
        <input class="form-control" id="validationCustom02" type="text" [(ngModel)]="variant.image_id"
          [ngModelOptions]="{standalone: true}">
      </div> -->
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="this.send()"
    [disabled]="this.image.src == '' || this.variant.size.length == 0">Save</button>
  <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="this.close()">Close</button>
</div>
