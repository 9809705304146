import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CallaBackendConfig } from './config';
import { LocalStoreService } from './local-store.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private store: LocalStoreService, private http: HttpClient) {}

  getAuthHeader(): HttpHeaders {
    const tk = this.store.getItem('tk');
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + tk,
    });
  }

  createCategory(data) {
    return this.http
      .post(CallaBackendConfig.CATEGORY, JSON.stringify(data), {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  createSubCategory(data) {
    return this.http
      .post(CallaBackendConfig.SUB_CATEGORY, JSON.stringify(data), {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  updateCategory(data) {
    return this.http
      .put(CallaBackendConfig.CATEGORY, JSON.stringify(data), {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  updateSubCategory(data) {
    return this.http
      .put(CallaBackendConfig.SUB_CATEGORY, JSON.stringify(data), {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  getCategories() {
    return this.http
      .get(CallaBackendConfig.CATEGORIES)
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  getSubCategories() {
    return this.http
      .get(CallaBackendConfig.SUB_CATEGORIES)
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  getSubCategoriesWithID(id) {
    return this.http
      .get(CallaBackendConfig.SUB_CATEGORIES + `/${id}`)
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  deleteCategory(data) {
    return this.http
      .delete(CallaBackendConfig.CATEGORY, {
        headers: this.getAuthHeader(),
        body: JSON.stringify(data),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  deleteSubategory(data) {
    return this.http
      .delete(CallaBackendConfig.SUB_CATEGORY, {
        headers: this.getAuthHeader(),
        body: JSON.stringify(data),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  handleError(err) {
    return err;
  }
}
