import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AngularFireDatabase,
  AngularFireList,
} from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FileUpload } from '../data/file-upload';
import { LocalStoreService } from './local-store.service';
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private basePath = '/product-images';
  constructor(
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private store: LocalStoreService
  ) {}

  getAuthHeader(): HttpHeaders {
    const tk = this.store.getItem('tk');
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + tk,
    });
  }

  pushFileToStorage(fileUpload: FileUpload): Observable<number> {
    const filePath = `${this.basePath}/${fileUpload.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);
    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe((downloadURL) => {
            fileUpload.url = downloadURL;
            fileUpload.name = fileUpload.file.name;
            this.saveFileData(fileUpload);
          });
        })
      )
      .subscribe();
    return uploadTask.percentageChanges();
  }

  async pushFileToStorageDL(
    fileUpload: FileUpload,
    fileName: string
  ): Promise<string> {
    const uuid = this.store.getItem('uuid');
    const filePath = `${this.basePath}/${uuid}/${fileName}`;
    const uploadTask = this.storage.upload(filePath, fileUpload.file);
    const url = await (
      await uploadTask
    ).ref
      .getDownloadURL()
      .then((url) => url)
      .catch((err) => err);
    if (typeof url === 'string') {
      return url;
    } else {
      throw new TypeError('HUpload');
    }
  }

  private saveFileData(fileUpload: FileUpload): void {
    this.db.list(this.basePath).push(fileUpload);
  }
  getFiles(numberItems): AngularFireList<FileUpload> {
    return this.db.list(this.basePath, (ref) => ref.limitToLast(numberItems));
  }
  // deleteFile(fileUpload: FileUpload): void {
  //   this.deleteFileDatabase(fileUpload.key)
  //     .then(() => {
  //       this.deleteFileStorage(fileUpload.name);
  //     })
  //     .catch((error) => console.log(error));
  // }
  private deleteFileDatabase(key: string): Promise<void> {
    return this.db.list(this.basePath).remove(key);
  }
  async deleteFileStorage(user_uuid: string, name: string) {
    try {
      const storageRef = this.storage.ref(`${this.basePath}/${user_uuid}`);
      storageRef.child(name).delete();
    } catch (error) {
      console.log(error);
    }
  }
}
