import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/shared/service/category.service';
import { ProductService } from 'src/app/shared/service/product.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  public product_list = [];
  public productKeys = [];
  public categories = [];
  public category = -1;
  public subcategory = -1;
  public subcategories = [];
  loading: boolean = false;
  maxTextLength = 20;

  constructor(
    private productService: ProductService,
    private categoryService: CategoryService,
    private router: Router
  ) {
    // this.product_list = productDB.product;
  }

  ngOnInit() {
    this.getProducts();
    this.getCategories();
  }

  async getCategories() {
    const response = await this.categoryService.getCategories();
    if (!response.data) {
      return;
    }
    this.categories = response.data;
  }

  async getSubcategories(id) {
    const response = await this.categoryService.getSubCategoriesWithID(id);
    if (!response.data) {
      return;
    }
    const subcategories: Array<any> = response.data;
    this.subcategories = subcategories.sort(this.compare);
  }

  private compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  changeCategory(id) {
    if (parseInt(id) == -1) {
      this.getProducts();
    } else {
      this.getProductWithCategory(id);
    }
    //Change the list accoring the category id.
    this.getSubcategories(id);
  }

  changeSubcategory(id) {
    if (parseInt(id) == -1) {
      this.getProductWithCategory(this.category);
      return;
    }

    this.getProductWithSubcategory(id);
  }

  // async getProducts() {
  //   this.loading = true;
  //   const response = await this.productService.getProduct();
  //   if (!response) {
  //     this.loading = false;
  //     return;
  //   }
  //   await this.consolidateProducts(response);
  //   this.loading = false;
  // }

  // async getProductWithCategory(category) {
  //   this.loading = true;
  //   const response = await this.productService.getProductWithFilterCategory(
  //     category
  //   );
  //   if (!response) {
  //     this.loading = false;
  //     return;
  //   }
  //   await this.consolidateProducts(response);
  //   this.loading = false;
  // }

  // async getProductWithSubategory(subcategory) {
  //   this.loading = true;
  //   const response = await this.productService.getProductWithFilterSubcategory(
  //     subcategory
  //   );
  //   if (!response) {
  //     this.loading = false;
  //     return;
  //   }
  //   await this.consolidateProducts(response);
  //   this.loading = false;
  // }

  // async consolidateProducts(products) {
  //   // const productList = Object.values(products);
  //   this.productKeys = Object.keys(products);
  //   const productList = [];
  //   for (const key of this.productKeys) {
  //     const product = products[key];
  //     const data = {
  //       ...product,
  //       productKey: key,
  //     };
  //     productList.push(data);
  //   }
  //   this.product_list = productList
  //     .map((p) => {
  //       let pr = JSON.parse(
  //         JSON.stringify({ ...Object.assign({}, p), indexImage: 0 })
  //       );
  //       if (pr.sale) {
  //         pr.discountPrice = Math.round(
  //           pr.price - (pr.price * pr.discount) / 100
  //         );
  //       }
  //       return pr;
  //     })
  //     .reverse();
  //   return;
  // }

  // Method to retrieve all products
  async getProducts() {
    await this.fetchAndConsolidateProducts(() =>
      this.productService.getProduct()
    );
  }

  // Method to retrieve products by category
  async getProductWithCategory(category) {
    await this.fetchAndConsolidateProducts(() =>
      this.productService.getProductWithFilterCategory(category)
    );
  }

  // Method to retrieve products by subcategory
  async getProductWithSubcategory(subcategory) {
    await this.fetchAndConsolidateProducts(() =>
      this.productService.getProductWithFilterSubcategory(subcategory)
    );
  }

  // Helper method to fetch and consolidate products
  async fetchAndConsolidateProducts(fetchMethod) {
    try {
      this.loading = true;
      const response = await fetchMethod();
      if (!response) return;
      await this.consolidateProducts(response);
    } finally {
      this.loading = false;
    }
  }

  // Method to consolidate products
  async consolidateProducts(products) {
    this.productKeys = Object.keys(products);
    const productList = this.productKeys.map((key) => {
      let product = {
        ...products[key],
        productKey: key,
        indexImage: 0, // Set indexImage here to avoid re-assignment
      };

      if (product.sale) {
        product.discountPrice = Math.round(
          product.price - (product.price * product.discount) / 100
        );
      }

      return product;
    });

    productList.sort((a, b) => b.date_created - a.date_created);

    this.product_list = productList;
  }

  editProduct(product) {
    this.router.navigateByUrl('products/physical/update-product', {
      state: {
        productKey: product.productKey,
        product: product,
        mode: 'verified',
      },
    });
  }

  changeVariant(productIndex, variantIndex) {
    const product = this.product_list[productIndex];
    if (product.indexImage == variantIndex) {
      return;
    }
    product.indexImage = variantIndex;
    this.product_list[productIndex] = product;
  }

  async deleteP(indexP) {
    swal
      .fire({
        title: 'Confirm',
        text: 'Are you sure you want to delete?',
        icon: 'question',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteProduct(this.productKeys[indexP]);
          swal.fire({
            title: 'Success',
            text: 'Product deleted',
            icon: 'success',
          });
          this.getCategories();
        }
        // else if (result.isDenied || result.isDismissed) {
        //   swal.fire('Changes are not saved', '', 'info');
        // }
      });
  }

  async deleteProduct(indexP) {
    await this.productService.deleteProduct(indexP);
    // this.category = -1;
    this.subcategory = -1;
    if (this.category == -1) {
      this.getProducts();
      return;
    }
    this.getProductWithCategory(this.category);
  }
}
