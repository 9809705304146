import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategoryService } from 'src/app/shared/service/category.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { AddImageComponent } from '../add-image/add-image.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
  public productForm: UntypedFormGroup;
  public counter: number = 1;
  public variants = [
    {
      variant_id: '',
      size: [],
      color: '',
      image_id: '',
    },
  ];
  public properties = { new: true, sale: false, category: -1, subcategory: -1 };
  public categories = [];
  public subcategories = [];
  public images = [
    {
      src: 'assets/images/user.png',
      image_id: '',
    },
  ];

  public image = {
    src: 'assets/images/pro3/1.jpg',
    image_id: '',
  };

  public loading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private categoryService: CategoryService,
    private productService: ProductService
  ) {
    this.productForm = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      price: ['', [Validators.required]],
      discount: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.getCategories();
  }

  toggleNew() {
    this.properties.new = !this.properties.new;
  }

  toggleSale() {
    this.properties.sale = !this.properties.sale;
  }

  async addProduct() {
    const filteredImages = this.images.filter((image) => {
      if (image.image_id && image.image_id != '') return image;
    });
    const filteredVariants = this.variants.filter((v) => {
      if (v.variant_id && v.variant_id != '') return v;
    });
    if (filteredImages.length == 0 || filteredVariants.length == 0) {
      return;
    }
    if (this.properties.category == -1 || this.properties.subcategory == -1) {
      return;
    }
    const product = {
      ...this.productForm.value,
      variants: filteredVariants,
      images: filteredImages,
      stock: this.counter,
      ...this.properties,
    };
    this.loading = true;
    const response = await this.productService.createProduct(product);
    swal.fire({
      title: 'Success',
      text: 'Product created',
      icon: 'success',
    });
    this.loading = false;

    this.resetForm();
  }

  resetForm() {
    this.images = [
      {
        src: 'assets/images/user.png',
        image_id: '',
      },
    ];

    this.variants = [
      {
        variant_id: '',
        size: [],
        color: '',
        image_id: '',
      },
    ];

    this.image = {
      src: 'assets/images/pro3/1.jpg',
      image_id: '',
    };

    this.productForm.reset();
  }

  async getCategories() {
    const response = await this.categoryService.getCategories();
    if (!response.data) {
      return;
    }
    this.categories = response.data;
  }

  async getSubcategories(id) {
    const response = await this.categoryService.getSubCategoriesWithID(id);
    if (!response.data) {
      return;
    }
    this.subcategories = response.data;
  }

  changeCategory(id) {
    this.getSubcategories(id);
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  addProductVariant() {
    this.images = [
      ...this.images,
      {
        src: 'assets/images/user.png',
        image_id: '',
      },
    ];

    this.variants = [
      ...this.variants,
      {
        variant_id: '',
        size: [],
        color: '',
        image_id: '',
      },
    ];
  }

  open(indexImage) {
    const checkImage = this.images[indexImage];
    if (checkImage.image_id != '') {
      this.image = checkImage;
      return;
    }
    const modalRef = this.modalService.open(AddImageComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });

    modalRef.result
      .then((objectData) => {
        this.images[indexImage] = objectData.image;
        this.variants[indexImage] = objectData.variant;
      })
      .catch((err) => {});
  }

  removeImage(i) {
    if (this.images.length == 1) return;
    this.images.splice(i, 1);
    this.variants.splice(i, 1);
  }

  editImage(indexImage) {
    const checkImage = this.images[indexImage];
    if (checkImage.image_id == '') {
      return;
    }
    const modalRef = this.modalService.open(AddImageComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });

    modalRef.componentInstance.editImage = {
      image: checkImage,
      variant: this.variants[indexImage],
    };

    modalRef.result
      .then((objectData) => {
        this.images[indexImage] = objectData.image;
        this.variants[indexImage] = objectData.variant;
      })
      .catch((err) => {});
  }
}
