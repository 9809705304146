<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Add Product </h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-5">
              <div class="add-product">
                <div class="row">
                  <div class="col-xl-9 xl-50 col-sm-6 col-9">
                    <img [src]="image.src" alt="" class="img-fluid image_zoom_1">
                  </div>
                  <div class="col-xl-3 xl-50 col-sm-6 col-3">
                    <div class="row" *ngFor="let item of images; let i=index">
                      <div class="col">
                        <div style="display: flex; align-items: center; justify-content: center; padding-bottom: 20px;">
                          <div (click)="removeImage(i)" *ngIf="this.images.length > 1"
                            style="position: absolute; right: 45%; top: 25%; width: 15px; height: 15px; color: #595959; cursor: pointer;">
                            <i class="fa fa-remove"></i>
                          </div>

                        </div>
                        <div style="display: flex; align-items: center; justify-content: center;">
                          <div (click)="editImage(i)" *ngIf="item.image_id != ''"
                            style="position: absolute; right: 45%; top: 50%; width: 15px; height: 15px; color: #595959; cursor: pointer;">
                            <i class="fa fa-pencil"></i>
                          </div>
                        </div>
                        <div class="box-input-file" (click)="open(i)">
                          <img class="imgUpload" alt="" [src]="item.src">
                        </div>
                      </div>
                    </div>
                    <div class="row" style="padding-top: 10px;">
                      <div class="col"><button type="button" class="btn btn-primary btn-sm"
                          (click)="addProductVariant()" [disabled]="this.images.length>7">+</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-7">
              <form class="needs-validation" [formGroup]="productForm" novalidate>
                <div class="add-product-form">
                  <div class="form-group row">
                    <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Title</label>
                    <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" type="text"
                      [formControl]="productForm.controls['title']" required>
                    <div *ngIf="productForm.controls.title.touched && productForm.controls.title.errors?.required"
                      class="text text-danger">
                      Title Name is required.
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Category</label>
                    <select class="form-control col-xl-8 col-sm-7" [(ngModel)]="properties.category"
                      [ngModelOptions]="{standalone: true}" (ngModelChange)="changeCategory($event)">
                      <option *ngFor="let category of categories" [ngValue]="category.id">
                        {{category.name}}</option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Subcategory</label>
                    <select class="form-control col-xl-8 col-sm-7" [(ngModel)]="properties.subcategory"
                      [ngModelOptions]="{standalone: true}">
                      <option *ngFor="let subcategory of subcategories" [ngValue]="subcategory.id">
                        {{subcategory.name}}</option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="validationCustom02" class="col-xl-3 col-sm-4 mb-0">Price</label>
                    <input class="form-control col-xl-8 col-sm-7" id="validationCustom02" type="number"
                      [formControl]="productForm.controls['price']" required="">
                    <div *ngIf="productForm.controls.price.touched && productForm.controls.price.errors?.required"
                      class="text text-danger">
                      Product Price is required.
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="validationCustom02" class="col-xl-3 col-sm-4 mb-0">Discount %</label>
                    <input class="form-control col-xl-8 col-sm-7" id="validationCustom02" type="number"
                      [formControl]="productForm.controls['discount']" required="">
                    <div *ngIf="productForm.controls.discount.touched && productForm.controls.discount.errors?.required"
                      class="text text-danger">
                      Product discount is required.
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-sm-4 mb-0">Total Stock</label>
                    <div class="qty-box1">
                      <div class="input-group">
                        <i class="fa fa-minus btnGtr1" (click)=decrement()></i>
                        <input class="touchspin1 text-center" name="counter" [value]="counter" type="text">
                        <i class="fa fa-plus btnLess1" (click)=increment()></i>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-sm-4 mb-0">New?</label>
                    <div class="col-xl-8 col-sm-7 pl-0 description-sm">
                      <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn"
                          [ngClass]="{'btn-primary': properties.new, 'btn-light': !properties.new}"
                          (click)="toggleNew()">Yes</button>
                        <button type="button" class="btn"
                          [ngClass]="{'btn-primary': !properties.new, 'btn-light': properties.new}"
                          (click)="toggleNew()">No</button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-sm-4 mb-0">On Sale?</label>
                    <div class="col-xl-8 col-sm-7 pl-0 description-sm">
                      <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn"
                          [ngClass]="{'btn-primary': properties.sale, 'btn-light': !properties.sale}"
                          (click)="toggleSale()">Yes</button>
                        <button type="button" class="btn"
                          [ngClass]="{'btn-primary': !properties.sale, 'btn-light': properties.sale}"
                          (click)="toggleSale()">No</button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-sm-4">Description</label>
                    <div class="col-xl-8 col-sm-7 pl-0 description-sm">
                      <textarea class="form-control col-xl-12 col-sm-12" id="validationCustom02"
                        [formControl]="productForm.controls['description']" required=""></textarea>
                      <div
                        *ngIf="productForm.controls.description.touched && productForm.controls.description.errors?.required"
                        class="text text-danger">
                        Product description is required.
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="form-group row mb-0">
                <div class="product-buttons col-sm-8 offset-xl-3 offset-sm-4">
                  <button type="button" class="btn btn-primary mr-1" (click)="addProduct()"
                    [disabled]="this.loading || !this.productForm.valid || this.properties.category == -1 || this.properties.subcategory == -1 || this.images[0].image_id == ''">{{this.loading?'...':'Add'}}</button>
                  <!-- <button type="button" class="btn btn-light" (click)="check()">Discard</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
