import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-decline-modal',
  templateUrl: './decline-modal.component.html',
  styleUrls: ['./decline-modal.component.scss'],
})
export class DeclineModalComponent implements OnInit {
  declineMessage: string = '';
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  close() {
    this.activeModal.dismiss('Closed');
  }

  send() {
    if (this.declineMessage == '') {
      return '';
    }
    this.activeModal.close({
      decline_reason: this.declineMessage,
    });
  }
}
