import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStoreService } from './local-store.service';
import { CallaBackendConfig } from './config';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private store: LocalStoreService,
    private router: Router,
    private http: HttpClient
  ) {}

  getAuthHeader(): HttpHeaders {
    const tk = this.store.getItem('tk');
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + tk,
    });
  }

  getStatus() {
    return this.http
      .get(CallaBackendConfig.GENERAL_STATISTICS, {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  handleError(err) {
    return err;
  }
}
