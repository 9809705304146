import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoryService } from 'src/app/shared/service/category.service';
import { FormCategoryComponent } from '../form-category/form-category.component';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  public categories = [];

  constructor(
    private modalService: NgbModal,
    private categoryService: CategoryService,
    private toastr: ToastrService
  ) {}

  open(data, action) {
    const modalData = {
      data: data,
      action: action,
      type: 'category',
    };
    const modalRef = this.modalService.open(FormCategoryComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });

    modalRef.componentInstance.modalData = modalData;

    modalRef.result
      .then(async (result) => {
        if (result.category.name == '' || result.category.img_src == '') {
          swal.fire({
            title: 'Empty Data',
            icon: 'error',
            text: 'Please do fill both fields',
          });
          return;
        }
        if (result.action == 'add') {
          //Create a new category and refresh.
          await this.addCategory(result.category);
          swal.fire({
            title: 'Success',
            text: 'Category created!',
            icon: 'success',
          });
          this.getCategories();
          return;
        }
        await this.updateCategory(result.category);
        swal.fire({
          title: 'Success',
          text: 'Category updated!',
          icon: 'success',
        });
        this.getCategories();
      })
      .catch((err) => {});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public settings = {
    actions: {
      position: 'right',
      add: false,
    },
    mode: 'external',
    columns: {
      numID: {
        title: 'ID',
        editable: false,
        filter: false,
      },
      image_seen: {
        title: 'Image',
        filter: false,
        type: 'html',
      },
      name: {
        title: 'Name',
        filter: false,
      },
    },
  };

  ngOnInit() {
    this.getCategories();
  }

  async getCategories() {
    const response = await this.categoryService.getCategories();
    if (!response.data) {
      return;
    }
    let num = 0;
    this.categories = response.data.map((category) => {
      num = num + 1;
      return {
        ...category,
        numID: num,
        image_seen: `<img src='${category.image_src}' class='imgTable'>`,
      };
    });
  }

  async updateCategory(category) {
    const response = await this.categoryService.updateCategory(category);
  }

  async deleteSrcCategory(category) {
    const response = await this.categoryService.deleteCategory(category);
  }

  async addCategory(category) {
    const response = await this.categoryService.createCategory(category);
  }

  async deleteCategory(event) {
    swal
      .fire({
        title: 'Confirm',
        text: 'Are you sure you want to delete?',
        icon: 'question',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteSrcCategory(event.data);
          swal.fire({
            title: 'Success',
            text: 'Category deleted!',
            icon: 'success',
          });
          this.getCategories();
        }
        // else if (result.isDenied || result.isDismissed) {
        //   swal.fire('Changes are not saved', '', 'info');
        // }
      });
  }
}
