import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";
import { LocalStoreService } from "./local-store.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private auth: AuthService,
    private storage: LocalStoreService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const jwtHelper = new JwtHelperService();
    const isExpired = jwtHelper.isTokenExpired(this.getToken());
    if (this.auth.checkAuth() && !isExpired) {
      // switch (state.url) {
      //   case "/order/add-order":
      //   case "/order/list-order":
      //     if (accountType !== "client") {
      //       this.router.navigateByUrl("/pages/404");
      //       return;
      //     }
      //     break;
      //   case "/management/items":
      //   case "/management/order-dispatcher":
      //     if (accountType === "client") {
      //       this.router.navigateByUrl("/pages/404");
      //       return;
      //     }
      //     break;
      //   default:
      //     break;
      // }
      return true;
    } else {
      this.router.navigateByUrl("auth/login");
    }
  }

  canActivate() {
    const jwtHelper = new JwtHelperService();
    const isExpired = jwtHelper.isTokenExpired(this.getToken());
    if (this.auth.checkAuth() && !isExpired) {
      return true;
    } else {
      this.router.navigateByUrl("auth/login");
    }
  }

  getToken(): string {
    return this.storage.getItem("tk");
  }
}
