import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ProductsRoutingModule } from './products-routing.module';
import { CategoryComponent } from './physical/category/category.component';
import { SubCategoryComponent } from './physical/sub-category/sub-category.component';
import { ProductListComponent } from './physical/product-list/product-list.component';
import { AddProductComponent } from './physical/add-product/add-product.component';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { ProductDetailComponent } from './physical/product-detail/product-detail.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormCategoryComponent } from './physical/form-category/form-category.component';
import { AddImageComponent } from './physical/add-image/add-image.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { UpdateProductComponent } from './physical/update-product/update-product.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UnverifiedStockListComponent } from './physical/unverified-stock-list/unverified-stock-list.component';
import { DeclineModalComponent } from './physical/decline-modal/decline-modal.component';
import { DeclinedStockListComponent } from './physical/declined-stock-list/declined-stock-list.component';
import { ExpiredStockListComponent } from './physical/expired-stock-list/expired-stock-list.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};

@NgModule({
  declarations: [
    CategoryComponent,
    SubCategoryComponent,
    ProductListComponent,
    AddProductComponent,
    DigitalCategoryComponent,
    DigitalSubCategoryComponent,
    DigitalListComponent,
    DigitalAddComponent,
    ProductDetailComponent,
    FormCategoryComponent,
    AddImageComponent,
    UpdateProductComponent,
    UnverifiedStockListComponent,
    DeclineModalComponent,
    DeclinedStockListComponent,
    ExpiredStockListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    ColorPickerModule,
    ProductsRoutingModule,
    Ng2SmartTableModule,
    NgbModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    NgSelectModule,
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
    NgbActiveModal,
  ],
})
export class ProductsModule {}
