import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth.service';
import { CategoryService } from 'src/app/shared/service/category.service';
import { FileUploadService } from 'src/app/shared/service/file-upload.service';
import { ProductService } from 'src/app/shared/service/product.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-expired-stock-list',
  templateUrl: './expired-stock-list.component.html',
  styleUrls: ['./expired-stock-list.component.scss'],
})
export class ExpiredStockListComponent implements OnInit {
  public product_list = [];
  public productKeys = [];
  public categories = [];
  public category = -1;
  public subcategory = -1;
  public subcategories = [];
  public initColor: string;
  loading: boolean = false;
  maxTextLength = 20;

  constructor(
    private productService: ProductService,
    private categoryService: CategoryService,
    private authService: AuthService,
    private auth: AngularFireAuth,
    private router: Router,
    private fileUploadService: FileUploadService
  ) {}

  ngOnInit(): void {
    this.getProducts();
    this.getCategories();
  }

  async getCategories() {
    const response = await this.categoryService.getCategories();
    if (!response.data) {
      return;
    }
    this.categories = response.data;
  }

  async getSubcategories(id) {
    const response = await this.categoryService.getSubCategoriesWithID(id);
    if (!response.data) {
      return;
    }
    const subcategories: Array<any> = response.data;
    this.subcategories = subcategories.sort(this.compare);
  }

  private compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  changeCategory(id) {
    if (parseInt(id) == -1) {
      this.getProducts();
    } else {
      this.getProductWithCategory(id);
    }
    //Change the list accoring the category id.
    this.getSubcategories(id);
  }

  changeSubcategory(id) {
    if (parseInt(id) == -1) {
      this.getProductWithCategory(this.category);
      return;
    }

    this.getProductWithSubategory(id);
  }

  // async getProducts() {
  //   this.loading = true;
  //   const responseU = await this.productService.getUProduct();
  //   if (responseU.code != 200 || !responseU.data) {
  //     this.loading = false;
  //     return;
  //   }
  //   await this.consolidateU(responseU.data);
  //   this.loading = false;
  // }

  // async consolidateU(rawData: any) {
  //   const uuidRaw = Object.keys(rawData);
  //   const productsRaw = Object.values(rawData);
  //   let product_list_new = [];

  //   for (let index = 0; index < uuidRaw.length; index++) {
  //     const productUUIDs = Object.keys(productsRaw[index]);
  //     const productList = Object.values(productsRaw[index]);
  //     for (
  //       let indexInternal = 0;
  //       indexInternal < productUUIDs.length;
  //       indexInternal++
  //     ) {
  //       let p = productList[indexInternal];
  //       if (!p.expired) {
  //         continue;
  //       }
  //       if (p.sale) {
  //         p = {
  //           ...p,
  //           discountPrice: Math.round(p.price - (p.price * p.discount) / 100),
  //         };
  //       }
  //       p = {
  //         ...p,
  //         user_uuid: uuidRaw[index],
  //         product_uuid: productUUIDs[indexInternal],
  //         indexImage: 0,
  //       };
  //       product_list_new.push(p);
  //     }
  //   }

  //   this.product_list = product_list_new;
  // }

  // Method to retrieve and process products
  async getProducts() {
    try {
      this.loading = true; // Indicate the loading process has started

      const responseU = await this.productService.getUProduct();

      // Check for a successful response
      if (responseU.code !== 200 || !responseU.data) {
        return;
      }

      await this.consolidateU(responseU.data);
    } finally {
      this.loading = false; // Reset loading state regardless of the outcome
    }
  }

  // Method to consolidate products
  async consolidateU(rawData) {
    const userUuids = Object.keys(rawData);
    const productsRaw = Object.values(rawData);
    let consolidatedProducts = [];

    userUuids.forEach((userUuid, index) => {
      const userProducts = this.processUserProducts(
        userUuid,
        productsRaw[index]
      );
      consolidatedProducts = consolidatedProducts.concat(userProducts);
    });

    //sort consolidatedProducts by date_created
    consolidatedProducts.sort((a, b) => b.date_created - a.date_created);

    this.product_list = consolidatedProducts;
  }

  // Helper method to process each user's products
  processUserProducts(userUuid, userProducts) {
    return Object.entries(userProducts)
      .map(([productUuid, product]) =>
        this.processProduct(userUuid, productUuid, product)
      )
      .filter(Boolean); // Remove null entries (products that were skipped)
  }

  // Method to process individual product
  processProduct(userUuid, productUuid, product) {
    if (!product.expired) {
      return null; // Skip this product if it's not expired
    }

    if (product.sale) {
      product = {
        ...product,
        discountPrice: Math.round(
          product.price - (product.price * product.discount) / 100
        ),
      };
    }

    return {
      ...product,
      user_uuid: userUuid,
      product_uuid: productUuid,
      indexImage: 0,
    };
  }

  async getProductWithCategory(category) {
    this.loading = true;
    const response = await this.productService.getProductWithFilterCategory(
      category
    );
    if (!response) {
      this.loading = false;
      return;
    }
    await this.consolidateProducts(response);
    this.loading = false;
  }

  async getProductWithSubategory(subcategory) {
    this.loading = true;
    const response = await this.productService.getProductWithFilterSubcategory(
      subcategory
    );
    if (!response) {
      this.loading = false;
      return;
    }
    await this.consolidateProducts(response);
    this.loading = false;
  }

  async consolidateProducts(products) {
    const productList = Object.values(products);
    this.productKeys = Object.keys(products);
    this.product_list = productList.map((p) => {
      let pr = JSON.parse(
        JSON.stringify({ ...Object.assign({}, p), indexImage: 0 })
      );
      if (pr.sale) {
        pr.discountPrice = Math.round(
          pr.price - (pr.price * pr.discount) / 100
        );
      }
      return pr;
    });
  }

  // editProduct(product, productKeyIndex) {
  //   this.router.navigateByUrl('products/physical/update-product', {
  //     state: {
  //       productKey: this.productKeys[productKeyIndex],
  //       product: product,
  //       mode: 'unverified',
  //     },
  //   });
  // }

  changeVariant(productIndex, variantIndex) {
    const product = this.product_list[productIndex];
    if (product.indexImage == variantIndex) {
      return;
    }
    product.indexImage = variantIndex;
    this.product_list[productIndex] = product;
  }

  private async reauthService() {
    const response = await this.authService.getAdminFirebaseToken();
    if (response?.code != 200) {
      swal.fire({
        title: 'Oops!',
        text: 'something wrong when fetching admin token',
        icon: 'error',
      });
      return;
    }
    const customtoken = response?.data;
    await this.auth.signInWithCustomToken(customtoken);
  }

  async deletePhotos(product: any) {
    await this.reauthService();
    const user_uuid = product?.user_uuid;
    const image_filenames = [];
    product?.images.forEach((image) => {
      image_filenames.push(image.file_name);
    });
    for (let index = 0; index < image_filenames.length; index++) {
      const file_name = image_filenames[index];
      await this.fileUploadService.deleteFileStorage(user_uuid, file_name);
    }
  }

  async deleteProduct(product) {
    const user_uuid = product?.user_uuid;
    const product_uuid = product?.product_uuid;
    await this.deletePhotos(product);
    const resp = await this.productService.deleteUserProduct({
      product_uuid: product_uuid,
      user_uuid: user_uuid,
    });
    if (resp?.code != 200) {
      swal.fire({
        title: 'Oops!',
        text: 'something wrong when deleting a user product',
        icon: 'error',
      });
      return;
    }
    this.subcategory = -1;
    if (this.category == -1) {
      this.getProducts();
      return;
    }
    this.getProductWithCategory(this.category);
  }
}
