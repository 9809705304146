<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Sub Category</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-8">
              <div class="form-group">
                <label for="validationCustom01" class="col-xl-3 col-sm-4 pl-0">Category</label>
                <select class="form-control col-xl-8 col-sm-7" [(ngModel)]="category"
                  [ngModelOptions]="{standalone: true}" (ngModelChange)="changeCategory($event)">
                  <option value="-1">All</option>
                  <option *ngFor="let category of categories" [ngValue]="category.id">
                    {{category.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="btn-popup pull-right">
            <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
              data-target="#exampleModal" (click)="open([],'add')">Add Sub Category</button>
          </div>
          <div id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive">
              <ng2-smart-table [settings]="settings" [source]="sub_categories" (edit)="this.open($event, 'update')"
                (delete)="this.deleteSCategory($event)">
              </ng2-smart-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
