import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CallaBackendConfig } from './config';
import { LocalStoreService } from './local-store.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private store: LocalStoreService, private http: HttpClient) {}

  getAuthHeader(): HttpHeaders {
    const tk = this.store.getItem('tk');
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + tk,
    });
  }

  createProduct(data) {
    return this.http
      .post(CallaBackendConfig.PRODUCT, JSON.stringify(data), {
        // headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  updateProduct(id, data) {
    return this.http
      .put(
        CallaBackendConfig.PRODUCT_UPDATE + `/${id}.json`,
        JSON.stringify(data),
        {
          // headers: this.getAuthHeader(),
        }
      )
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  deleteProduct(id) {
    return this.http
      .delete(CallaBackendConfig.PRODUCT_UPDATE + `/${id}.json`)
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  deleteUserProduct(data) {
    return this.http
      .delete(CallaBackendConfig.DELETE_USER_PRODUCT, {
        body: JSON.stringify(data),
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  getProduct() {
    return this.http
      .get(CallaBackendConfig.PRODUCT, {
        // headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  getUProduct() {
    return this.http
      .get(CallaBackendConfig.UPRODUCT, {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  getDeclinedProducts() {
    return this.http
      .get(CallaBackendConfig.UPRODUCT, {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  addUProduct(data) {
    return this.http
      .post(CallaBackendConfig.UPRODUCT, JSON.stringify(data), {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  removeUProduct(data) {
    return this.http
      .delete(CallaBackendConfig.UPRODUCT, {
        body: JSON.stringify(data),
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  declineProduct(data) {
    return this.http
      .post(CallaBackendConfig.UPRODUCT_DECLINE, JSON.stringify(data), {
        headers: this.getAuthHeader(),
      })
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  getProductWithFilterCategory(category) {
    return this.http
      .get(
        `${CallaBackendConfig.PRODUCT}?orderBy="category"&equalTo=${category}`
      )
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }

  getProductWithFilterSubcategory(subcategory) {
    return this.http
      .get(
        `${CallaBackendConfig.PRODUCT}?orderBy="subcategory"&equalTo=${subcategory}`
      )
      .toPromise()
      .then((res) => res)
      .catch((err) => err);
  }
}
